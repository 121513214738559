@import "../stylesheets/base/variables"
@import "../stylesheets/components/pspdfkit-custom-buttons"

.PSPDFKit-Page-Indicator-Prev, .PSPDFKit-Page-Indicator-Next
  display: none

.PSPDFKit-Page-Indicator-Input
  border-radius: 3px

.PSPDFKit-Toolbar-DropdownGroup-Ink
  margin-left: auto

.PSPDFKit-Comment-Marker-Annotation, .PSPDFKit-Icon-CommentIndicator
  cursor: default

.PSPDFKit-Comment-Editor-Input
  font-size: 16px

.custom-avatar
  height: 32px
  width: 32px
  background-size: cover
  background-position: center
  display: flex
  justify-content: center
  align-items: center
  border-radius: 100%
  border: 1px solid #4636e3

  .initials
    color: #FFF

.PSPDFKit-annotation-author-container
  position: absolute
  top: -45px
  min-width: 150px
  height: auto
  background: #ECEFF1
  font-size: 10px
  padding: 5px 10px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25)
  border-radius: 2px
  text-align: left
  display: flex
  justify-content: space-between

  .PSPDFKit-annotation-author
    font-weight: 600

  .PSPDFKit-annotation-created-at
    color: #4636e3

  .custom-avatar
    border-radius: 100%
    height: 24px
    width: 24px
    align-self: center
    border: 1px solid #4636e3

.thumbnails-button.d-none + .BaselineUI-Separator
  display: none
