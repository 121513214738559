.annotation-button,
.fullscreen-button
  align-items: center
  height: 44px
  color: #3d424e
  cursor: pointer
  font-size: 16px
  display: flex
  justify-content: center
  touch-action: pan-x
  transition: all .15s

  &:hover
    background-color: #f0f3f9

  &--icon
    display: flex
    padding: 0 10px

  button
    border: none
    background: transparent

    svg
      height: 24px
      width: 24px

.thumbnails-button,
.PSPDFKit-Toolbar-Button-Zoom-In,
.PSPDFKit-Toolbar-Button-Zoom-Out,
.PSPDFKit-Toolbar-Button-Fit-To-Page
  @media (max-width: $break-small)
    display: none

.annotation-button
  padding-right: 11px
  padding-left: 11px

.custom-button
  color: rgb(43,46,54)

  &--active,
  &--active:focus,
  &--active:hover
    background-color: #4636e3
    color: #FFF

    button
      color: #FFF

    svg
      path
        fill: #FFF
        stroke: #FFF
